import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.pt-br"

const IndexEN = ({ location, data }) => {
  return (
    <>
      <Metatags
        title="Multilógica | Sobre nós"
        description="Fornecedor de maquinário para colchões"
        url="https://newsite.multilogica.ind.br/pt-br/sobre-nós"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchão, máquina de costura, mammut, beckmann sew"
        language="pt-br"
      />
      <NavBar location={location["pathname"]} />
      <div className="w-full">
        <GatsbyImage
          image={getImage(data.bannerAbout)}
          backgroundColor={false}
          alt="mattress machines"
        />
      </div>
      <div className="bg-mi-blue">
        <div className="container mx-auto">
          <div className="h-20" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-white text-4xl font-semibold m-4">
              Sobre nós
            </div>
          </div>
          <div className="h-20" />
        </div>
      </div>
      <div className="h-0 md:h-8" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 place-self-center mr-12">
            <div className="text-mi-blue text-xl m-4">
              Fundada em 1990 com ênfase em eletrônica e automação industrial, a
              Multilógica vem provendo serviços de desenvolvimento, reparo e
              automação para máquinas industriais em diversas áreas, que vão
              desde a indústria do vidro, farmacêutica e principalmente a
              indústria colchoeira, englobando o comércio de máquinas e peças de
              reposição.
            </div>
          </div>
          <div className="col-span-1">
            <GatsbyImage
              image={getImage(data.about1)}
              backgroundColor={false}
              alt="machine maintenance"
            />
          </div>
        </div>
        <div className="h-0 md:h-8" />
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 place-self-center mr-12">
            <div className="text-mi-blue text-xl m-4">
              Norteada por princípios éticos e morais temos por objetivo
              excelência em atendimento, fornecendo as melhores máquinas
              conhecidas no mercado, provendo o melhor serviço e ligando os
              fabricantes com o cliente final de forma transparente e eficaz,
              tangendo a vanguarda de tecnologia em máquinas de alta
              produtividade.
            </div>
          </div>
          <div className="col-span-1">
            <GatsbyImage
              image={getImage(data.about2)}
              backgroundColor={false}
              alt="machine maintenance"
            />
          </div>
        </div>
        <div className="h-0 md:h-8" />
      </div>
      <div className="bg-mi-gray">
        <div className="h-20" />
        <div className="mx-auto text-center">
          <div className="text-mi-blue text-2xl font-semibold">
            Uma história baseada na experiência e na confiança mútua com o
            cliente.
          </div>
        </div>
        <div className="h-20" />
      </div>
      <div className="h-20" />
      <div className="container mx-auto">
        <div className="h-0 md:h-8" />
        <div className="max-w-4xl  mx-auto">
          <div className="text-mi-blue text-xl  font-bold m-4">
            Compromisso e Confiança
          </div>
          <div className="text-mi-blue text-xl  font-semibold m-4">
            Cumprimos nossos compromissos e assumimos a responsabilidade por
            nosso desempenho em todas as nossas decisões e ações.
          </div>
          <div className="text-mi-blue text-xl  font-bold m-4">
            Experiência e Competência
          </div>
          <div className="text-mi-blue text-xl  font-semibold m-4">
            A nossa missão está profundamente integrada com a excelência:
            colaboração, análise e desenvolvimento de soluções personalizadas.
          </div>
          <div className="text-mi-blue text-xl  font-bold m-4">
            Integridade e Ética
          </div>
          <div className="text-mi-blue text-xl  font-semibold m-4">
            Acreditamos na honestidade, na ética e na integridade, por isso
            contribuimos decisivamente em nosso entorno com o objetivo de
            impactar positivamente na sociedade.
          </div>
        </div>
        <div className="h-20" />
      </div>
      <Footer lang="en" />
    </>
  )
}

export default IndexEN

export const aboutPT = graphql`
  query {
    bannerAbout: file(relativePath: { eq: "about-us/Banner_quem_somos.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
    about1: file(relativePath: { eq: "about-us/Galeria_SobreNos_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
    about2: file(relativePath: { eq: "about-us/Galeria_SobreNos_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
  }
`
